import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { getFeatureToggles } from '../../../../../config/appSettings';
import { UserNote } from '../../../../../types/domain/offering/user-note';
import useFollowOfferingMutation from '../../../../shared/follow-offering/useFollowOfferingMutation';
import {
  fetchOfferingActions,
  selectError as selectOfferingError,
  selectOffering,
} from '../../../shared/ducks';
import OfferingProfileScreen from '../../components/OfferingProfileScreen';
import {
  fetchUserNoteRequest,
  resetState,
  saveUserNoteRequest,
  selectError,
  selectUserNote,
} from '../../ducks';
import DSOfferingProfileScreen from './OfferingProfileScreen';

const mapStateToProps = state => ({
  profileError: selectError(state),
  offeringError: selectOfferingError(state),
  offering: selectOffering(state),
  userNote: selectUserNote(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchOffering: fetchOfferingActions.request,
      fetchUserNote: fetchUserNoteRequest,
      saveUserNote: saveUserNoteRequest,
      resetState,
    },
    dispatch
  ),
});

type RouterProps = RouteComponentProps<{ offeringId: string }>;

type OwnProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & RouterProps;

export const OfferingProfileContainerComponent: React.FC<Props> = ({
  actions,
  match,
  offering,
  userNote,
  profileError,
  offeringError,
}) => {
  const { offeringId } = match.params;
  const { isConvertsInDLOn, isATMInDLOn, isDSOfferingProfileTemplateOn } = getFeatureToggles();

  const { setFollowingOffering } = useFollowOfferingMutation({
    refetch: () => actions.fetchOffering({ offeringId }),
  });

  useEffect(() => {
    actions.fetchOffering({ offeringId });
    actions.fetchUserNote({ offeringId });
  }, [actions, offeringId]);

  useEffect(
    () => () => {
      actions.resetState();
    },
    [actions]
  );

  const onSaveUserNote = (userNoteDto: Partial<UserNote>) => {
    actions.saveUserNote({
      offeringId,
      userNote: {
        id: userNoteDto.id!,
        note: userNoteDto.note!,
      },
    });
  };

  const onToggleFollowOffering = async (follow: boolean) => {
    await setFollowingOffering(offeringId, follow);
  };

  const error = offeringError || profileError;

  if (!offering && !error) {
    return null;
  }

  if (
    offering &&
    ((offering.type === 'CONVERTIBLE' && isConvertsInDLOn) ||
      (offering.type === 'ATM' && isATMInDLOn) ||
      isDSOfferingProfileTemplateOn)
  ) {
    return (
      <DSOfferingProfileScreen
        offeringId={offering.id}
        offeringType={offering.type}
        userNote={userNote}
        onSaveUserNote={onSaveUserNote}
        isFollowing={offering.userIsFollowing}
        onToggleFollowOffering={() => onToggleFollowOffering(!offering.userIsFollowing)}
      />
    );
  }

  return (
    <OfferingProfileScreen
      error={error}
      offering={offering}
      userNote={userNote}
      onSaveUserNote={onSaveUserNote}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingProfileContainerComponent);
