import { apiTypes, PageError } from '@cmg/common';
import { offeringProfileScreenSelector } from '@cmg/e2e-selectors';
import classNames from 'classnames';
import React from 'react';

import { Offering } from '../../../../types/domain/offering/offering';
import { UserNote } from '../../../../types/domain/offering/user-note';
import { isInternationalOfferingsOn } from '../../../datalab/model/utils';
import ScreenContent from '../../../shared/layout/ScreenContent';
import ScreenHeader from '../../../shared/layout/ScreenHeader';
import OfferingSubHeader from '../../shared/components/OfferingSubHeader';
import OfferingProfile from './OfferingProfile';

type Props = {
  error: apiTypes.GenericServerError | null;
  offering?: Offering;
  userNote?: UserNote | null;
  onSaveUserNote?: (userNote: UserNote) => void;
};

export default class OfferingProfileScreen extends React.PureComponent<Props> {
  render() {
    const { offering, userNote, onSaveUserNote, error } = this.props;

    const showInternational = isInternationalOfferingsOn();
    return (
      <div
        className={classNames('offering-profile')}
        data-test-id={offeringProfileScreenSelector.testId}
      >
        <ScreenHeader />
        <ScreenContent renderSubHeader={() => offering && <OfferingSubHeader />}>
          {error && <PageError error={error} />}
          {offering && !error && (
            <OfferingProfile
              offering={offering}
              onSaveUserNote={onSaveUserNote}
              userNote={userNote}
              showInternational={showInternational}
            />
          )}
        </ScreenContent>
      </div>
    );
  }
}
