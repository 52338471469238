import { envVarUtil, urlUtil } from '@cmg/common';

const {
  getOrThrow,
  parseBoolOrThrow,
  parseFloatOrThrow,
  getLocalStorageEnvOverrides,
  getSessionEnvOverrides,
} = envVarUtil;

// Environment variables provided by create-react-app build time variable injection.
// Exported for testing purposes
export const buildTimeEnvVars = {
  NODE_ENV: process.env.NODE_ENV, // set based on react-scripts (yarn start vs yarn test vs yarn build)
  REACT_APP_CLUSTER_ENVIRONMENT: process.env.REACT_APP_CLUSTER_ENVIRONMENT,
  REACT_APP_ROOT_DOMAIN: process.env.REACT_APP_ROOT_DOMAIN,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_TRACING_ORIGIN: process.env.REACT_APP_DATADOG_TRACING_ORIGIN,
  REACT_APP_CLIENT_APP_VERSION: process.env.REACT_APP_CLIENT_APP_VERSION,
  REACT_APP_BUILD_CLIENT_APP_VERSION: process.env.REACT_APP_BUILD_CLIENT_APP_VERSION,
  REACT_APP_OIDC_AUTHORITY_BASE_URL: process.env.REACT_APP_OIDC_AUTHORITY_BASE_URL,
  REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES:
    process.env.REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES,
  REACT_APP_NOTIFICATIONS_ON: process.env.REACT_APP_NOTIFICATIONS_ON,
  REACT_APP_IDENTITY_SPA_BASE_URL: process.env.REACT_APP_IDENTITY_SPA_BASE_URL,
  REACT_APP_IDENTITY_SERVICE_BASE_URL: process.env.REACT_APP_IDENTITY_SERVICE_BASE_URL,
  REACT_APP_DASHBOARD_ON: process.env.REACT_APP_DASHBOARD_ON,
  REACT_APP_PUBLIC_XC_DEMO_TRAILER_VIDEO_BASE_URL:
    process.env.REACT_APP_PUBLIC_XC_DEMO_TRAILER_VIDEO_BASE_URL,
  REACT_APP_ENABLE_HUBSPOT_SUPPORT_FORM: process.env.REACT_APP_ENABLE_HUBSPOT_SUPPORT_FORM,
  REACT_APP_HUBSPOT_SUPPORT_FORM_PORTAL_ID: process.env.REACT_APP_HUBSPOT_SUPPORT_FORM_PORTAL_ID,
  REACT_APP_HUBSPOT_SUPPORT_FORM_ID: process.env.REACT_APP_HUBSPOT_SUPPORT_FORM_ID,
  REACT_APP_XC_SPA_BASE_URL: process.env.REACT_APP_XC_SPA_BASE_URL,
  REACT_APP_PUBSUB_COMMUNICATION: process.env.REACT_APP_PUBSUB_COMMUNICATION,
  REACT_APP_SIGNAL_LOG_LEVEL: process.env.REACT_APP_SIGNAL_LOG_LEVEL,
  REACT_APP_ENABLE_INTERNATIONAL_OFFERINGS_IN_DL:
    process.env.REACT_APP_ENABLE_INTERNATIONAL_OFFERINGS_IN_DL,
  REACT_APP_DLGW_GRAPHQL_API_URL: process.env.REACT_APP_DLGW_GRAPHQL_API_URL,
  REACT_APP__ENABLE_FEATURE__CONVERTS_OFFERINGS_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__CONVERTS_OFFERINGS_IN_DL,
  REACT_APP__ENABLE_FEATURE__DLGW_OFFERING_PROFILE_IN_DL_ECM_26074:
    process.env.REACT_APP__ENABLE_FEATURE__DLGW_OFFERING_PROFILE_IN_DL_ECM_26074,
  REACT_APP__ENABLE_FEATURE__CONVERTS_OFFERINGS_IN_DL_PHASE_TWO_ECM_30426:
    process.env.REACT_APP__ENABLE_FEATURE__CONVERTS_OFFERINGS_IN_DL_PHASE_TWO_ECM_30426,
  REACT_APP__ENABLE_FEATURE__ATM_OFFERINGS_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__ATM_OFFERINGS_IN_DL,
  REACT_APP__ENABLE_FEATURE__RESTRICT_FILED_US_OFFERINGS_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__RESTRICT_FILED_US_OFFERINGS_IN_DL,
  REACT_APP__ENABLE_FEATURE__NEW_OFFERING_FIELDS_ECM_36762:
    process.env.REACT_APP__ENABLE_FEATURE__NEW_OFFERING_FIELDS_ECM_36762,
  REACT_APP__ENABLE_FEATURE__NEW_DIRECT_OFFERING_FIELDS_ECM_38278:
    process.env.REACT_APP__ENABLE_FEATURE__NEW_DIRECT_OFFERING_FIELDS_ECM_38278,
  REACT_APP__ENABLE_FEATURE__CALENDAR_USING_GRAPHQL:
    process.env.REACT_APP__ENABLE_FEATURE__CALENDAR_USING_GRAPHQL,
  REACT_APP__ENABLE_FEATURE__CORNERSTONE_INVESTOR_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__CORNERSTONE_INVESTOR_IN_DL,
  REACT_APP__ENABLE_FEATURE__CONVERT_OFFERINGS_UPDATED_FILTERS:
    process.env.REACT_APP__ENABLE_FEATURE__CONVERT_OFFERINGS_UPDATED_FILTERS,
  REACT_APP__ENABLE_FEATURE__DESIGN_SYSTEM_OFFERING_PROFILE_TEMPLATE_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__DESIGN_SYSTEM_OFFERING_PROFILE_TEMPLATE_IN_DL,
  REACT_APP__ENABLE_FEATURE__ATM_SELL_DOWN_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__ATM_SELL_DOWN_IN_DL,
  REACT_APP__ENABLE_FEATURE__GLOBAL_ECM_V2_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__GLOBAL_ECM_V2_IN_DL,
  REACT_APP__ENABLE_FEATURE__GLOBAL_LEAGUE_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__GLOBAL_LEAGUE_IN_DL,
  REACT_APP__ENABLE_FEATURE__LOCKUP_DATA_IN_DL:
    process.env.REACT_APP__ENABLE_FEATURE__LOCKUP_DATA_IN_DL,
  REACT_APP__ENABLE_FEATURE__DOTNET_COMPAT_ENDPOINTS:
    process.env.REACT_APP__ENABLE_FEATURE__DOTNET_COMPAT_ENDPOINTS,
};

// Merge runtime variables provided by runtime-env.js which is generated in the docker entrypoint.
// Runtime variables take precedence.
const mergedVars = {
  ...buildTimeEnvVars,
  ...globalThis.env,
  ...getLocalStorageEnvOverrides(),
  ...getSessionEnvOverrides(),
};

const appSettings = {
  env: {
    clusterEnvironment: getOrThrow(mergedVars.REACT_APP_CLUSTER_ENVIRONMENT),
    rootDomain: getOrThrow(mergedVars.REACT_APP_ROOT_DOMAIN),
    secureCookies: !urlUtil.isLocalhost(getOrThrow(mergedVars.REACT_APP_ROOT_DOMAIN)),
    // refers to local development. all other environments run a 'production' version.
    isDevelopment: mergedVars.NODE_ENV === 'development',
    identitySpaBaseUrl: getOrThrow(mergedVars.REACT_APP_IDENTITY_SPA_BASE_URL),
    identityServiceBaseUrl: getOrThrow(mergedVars.REACT_APP_IDENTITY_SERVICE_BASE_URL),
    publicXcDemoTrailerVideoBaseUrl: mergedVars.REACT_APP_PUBLIC_XC_DEMO_TRAILER_VIDEO_BASE_URL,
    hubspotSupportFormPortalId: mergedVars.REACT_APP_HUBSPOT_SUPPORT_FORM_PORTAL_ID,
    hubspotSupportFormId: mergedVars.REACT_APP_HUBSPOT_SUPPORT_FORM_ID,
    xcSpaBaseUrl: mergedVars.REACT_APP_XC_SPA_BASE_URL,
    dlgwGraphqlApiUrl: getOrThrow(mergedVars.REACT_APP_DLGW_GRAPHQL_API_URL, '/dlgw/graphql/'),
  },
  features: {
    isDashboardOn: parseBoolOrThrow(mergedVars.REACT_APP_DASHBOARD_ON, false),
    isNotificationsOn: parseBoolOrThrow(mergedVars.REACT_APP_NOTIFICATIONS_ON, false),
    isHubspotSupportFormOn: parseBoolOrThrow(
      mergedVars.REACT_APP_ENABLE_HUBSPOT_SUPPORT_FORM,
      false
    ),
    isInternationalInDLOn: parseBoolOrThrow(
      mergedVars.REACT_APP_ENABLE_INTERNATIONAL_OFFERINGS_IN_DL,
      false
    ),
    isConvertsInDLOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CONVERTS_OFFERINGS_IN_DL,
      false
    ),
    rubyMigrationCompatEndpoints: mergedVars.REACT_APP__ENABLE_FEATURE__DOTNET_COMPAT_ENDPOINTS,
    // Use new offering profile template that query from dlgw graphql api
    isDLGWOfferingProfileInDLOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__DLGW_OFFERING_PROFILE_IN_DL_ECM_26074,
      false
    ),
    isDLOfferingConvertsPhaseTwoOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CONVERTS_OFFERINGS_IN_DL_PHASE_TWO_ECM_30426,
      false
    ),
    isATMInDLOn: parseBoolOrThrow(mergedVars.REACT_APP__ENABLE_FEATURE__ATM_OFFERINGS_IN_DL, false),
    isRestrictFiledUSOfferingsInDLOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__RESTRICT_FILED_US_OFFERINGS_IN_DL,
      false
    ),
    isNewOfferingFieldsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__NEW_OFFERING_FIELDS_ECM_36762,
      false
    ),
    isNewDirectOfferingFieldsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__NEW_DIRECT_OFFERING_FIELDS_ECM_38278,
      false
    ),
    isCalendarUsingGraphqlOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CALENDAR_USING_GRAPHQL,
      false
    ),
    isCornerstoneInvestorInDLOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CORNERSTONE_INVESTOR_IN_DL,
      false
    ),
    isConvertOfferingsUpdatedFiltersOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CONVERT_OFFERINGS_UPDATED_FILTERS,
      false
    ),
    isDSOfferingProfileTemplateOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__DESIGN_SYSTEM_OFFERING_PROFILE_TEMPLATE_IN_DL,
      false
    ),
    isAtmSelldownOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ATM_SELL_DOWN_IN_DL,
      false
    ),
    isGlobalEcmV2InDLOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__GLOBAL_ECM_V2_IN_DL,
      false
    ),
    isGlobalLeagueInDlOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__GLOBAL_LEAGUE_IN_DL,
      false
    ),
    isLockupDataInDlOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__LOCKUP_DATA_IN_DL,
      false
    ),
  },
  client: {
    appVersion: getOrThrow(mergedVars.REACT_APP_CLIENT_APP_VERSION),
    appVersionBuild: getOrThrow(mergedVars.REACT_APP_BUILD_CLIENT_APP_VERSION),
    basename: '/',
  },
  logging: {
    mixPanelToken: getOrThrow(mergedVars.REACT_APP_MIXPANEL_TOKEN, 'mock-token'),
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'applicationId'
    datadogApplicationId: mergedVars.REACT_APP_DATADOG_APPLICATION_ID,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'clientToken'
    datadogClientToken: mergedVars.REACT_APP_DATADOG_CLIENT_TOKEN,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'allowedTracingOrigins' - single item of array
    datadogTracingOrigin: new RegExp(getOrThrow(mergedVars.REACT_APP_DATADOG_TRACING_ORIGIN)),
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'service'
    datadogServiceName: 'ecm-client-web',
  },
  signalR: {
    signalRLogLevel: parseFloatOrThrow(mergedVars.REACT_APP_SIGNAL_LOG_LEVEL, 2),
  },
  auth: {
    oidcAuthorityBaseUrl: getOrThrow(mergedVars.REACT_APP_OIDC_AUTHORITY_BASE_URL),
    tokenRenewalIntervalInMinutes: parseFloatOrThrow(
      mergedVars.REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES,
      30
    ),
    clientId: 'cmg-app',
    scopes: {
      dl: 'dl:full',
      mgmt_api: 'mgmt_api:full',
      xc: 'xc:full',
    },
  },
  notifications: {
    pollingIntervalInMin: 1,
  },
};

export type AppSettings = typeof appSettings;

export function getAppSettings(): Readonly<AppSettings> {
  return appSettings;
}

export function getEnvVars() {
  return appSettings.env;
}

export { appSettings };

export function getFeatureToggles() {
  return getAppSettings().features;
}
